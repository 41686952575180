import React, { useState, useRef, useEffect } from "react";
import "./Chat.css";

type Message = {
    text: string;
    isUser: boolean;
};

const Chat: React.FC = () => {
    const [showChat, setShowChat] = useState(false);
    const [messages, setMessages] = useState<Message[]>([]);
    const [inputValue, setInputValue] = useState("");
    const [isBotTyping, setIsBotTyping] = useState(false); // Novo estado para o loading do bot
    const chatContainerRef = useRef<HTMLDivElement>(null);

    // Efeito para rolar para o final do chat sempre que as mensagens mudarem
    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [messages, isBotTyping]);

    const handleStartChat = () => {
        setShowChat(true);
        handleSendMessage();
    };

    const handleSendMessage = async () => {
        if (inputValue.trim() === "") return;

        const userMessage = { text: inputValue, isUser: true };
        setMessages((prevMessages) => [...prevMessages, userMessage]);

        const formattedHistory = messages.map((message) => ({
            role: message.isUser ? "user" : "assistant",
            content: message.text,
        }));

        const payload = {
            question: inputValue,
            memory: formattedHistory,
        };

        setInputValue("");
        setIsBotTyping(true); // Mostra o loading do bot

        try {
            const response = await fetch("http://54.80.21.187/chat", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error(`Erro na requisição: ${response.statusText}`);
            }

            const data = await response.json();
            const botMessage = { text: data.response, isUser: false };
            setMessages((prevMessages) => [...prevMessages, botMessage]);
        } catch (error) {
            console.error("Erro ao enviar mensagem:", error);
            setMessages((prevMessages) => [
                ...prevMessages,
                { text: "Erro ao se comunicar com o servidor. Tente novamente.", isUser: false },
            ]);
        } finally {
            setIsBotTyping(false); // Remove o loading do bot
        }
    };

    return (
        <div className="chat-container">
            {!showChat ? (
                <div className="intro-container">
                    <div className="second-container">
                        <div className="imagem" />
                        <div className="texto">
                            <h1>Olá, eu sou a</h1>
                            <span className="highlight">Joana <br />Maria</span>
                            <p>
                                A inteligência Artificial criada pelo Green Thinking para poder te ajudar com o que precisar!
                            </p>
                            <h3>Vamos conversar?</h3>
                        </div>
                    </div>
                    <div className="chat-input-container">
                        <input
                            type="text"
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            placeholder="Digite sua mensagem aqui..."
                            className="chat-input"
                            onKeyDown={(e) => {
                                if (e.key === "Enter") handleStartChat();
                            }}
                        />
                        <button onClick={handleStartChat} className="start-chat-button">
                            Enviar
                        </button>
                    </div>
                </div>
            ) : (
                <div className="intro-container">
                    <div className="chat-messages" ref={chatContainerRef}>
                        {messages.map((message, index) => (
                            <div
                                key={index}
                                className={`chat-message ${message.isUser ? "user-message" : "other-message"
                                    }`}
                            >
                                {!message.isUser && (
                                    <div className="avatar-wrapper">
                                        {/* <img
                                            alt="User Avatar"
                                            className="user-avatar"
                                        /> */}
                                    </div>
                                )}
                                <div
                                    className={`message-text ${message.isUser ? "user-text" : "other-text"
                                        }`}
                                >
                                    {message.text}
                                </div>
                            </div>
                        ))}

                        {/* Loading do bot */}
                        {isBotTyping && (
                            <div className="chat-message other-message">
                                <div className="message-text other-text">
                                    <em>Joana Maria está digitando...</em>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="chat-input-container">
                        <input
                            type="text"
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            placeholder="Digite sua mensagem aqui..."
                            className="chat-input"
                            onKeyDown={(e) => {
                                if (e.key === "Enter") handleSendMessage();
                            }}
                        />
                        <button onClick={handleSendMessage} className="start-chat-button">
                            Enviar
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Chat;
